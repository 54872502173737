<div class="bet-amount-cont">
  <span *ngIf="betAmount" class="bet-amount">
    {{betAmount}} {{betAmount > 1 ? 'credits' : 'credit'}}
  </span>
  <span *ngIf="!betAmount" class="bet-amount">
    Place bet
  </span>
  <span class="total-amount" (click)="totalAmountClicked()">
    <span *ngIf="!showDollars">
      {{totalAmountCredits()}} {{totalAmount > 1 ? 'credits' : 'credit'}}
    </span>
    <span *ngIf="showDollars">
      ${{totalAmountDollars()}}
    </span>
  </span>
</div>

<div class="bet-buttons">
  <div class="middle-buttons">
    <button [disabled]="!canBet" class="bet-button first"
            (click)="betClicked(1)">Bet 1</button>

    <span class="denomination" (click)="denominationClicked()">
      <span *ngIf="denomination === 1">1$</span>
      <span *ngIf="denomination === 0.25">25¢</span>
      <span *ngIf="denomination === 0.05">&nbsp;5¢</span>
    </span>

    <button [disabled]="!canBet" class="bet-button"
            (click)="betClicked(5)">Bet 5</button>
  </div>
  <button [disabled]="!betAmount" class="bet-button deal" (click)="dealClicked()">Deal</button>
</div>

