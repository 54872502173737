<paytable [payouts]="PAYOUTS" [hand]="hand" [betAmount]="betAmount"></paytable>
<cards [cards]="cards"
       [selections]="selections"
       [highlights]="highlights"
       [canHold]="firstDeal"
       [gameOver]="gameOver"
       (cardHeld)="onCardHeld($event)"></cards>
<controls [selections]="selections"
          [canHold]="firstDeal"
          (cardHeld)="onCardHeld($event)"></controls>
<betting [betAmount]="betAmount"
         [totalAmount]="totalAmount"
         [canBet]="!firstDeal"
         [denomination]="denomination"
         (deal)="deal()"
         (bet)="bet($event)"
         (denominationChange)="onDenominationChange($event)"></betting>

<div *ngIf="showOptimal" class="optimal">
  <div *ngIf="isOptimal" class="message">Best cards chosen!</div>
  <div *ngIf="!isOptimal" class="reveal">
    <button (click)="revealOptimal()" class="reveal">
      <span *ngIf="!showingInitialCards">Reveal Best Cards</span>
      <span *ngIf="showingInitialCards">Show Result</span>
    </button>
  </div>
  <div *ngIf="isOptimal && streak > 1" class="streak">Streak: {{streak}}</div>
</div>
