<card [idx]="0" [held]="selections[0]" [value]="cards[0]"
      [highlight]="highlights[0]" (click)="toggleSelection(0)"></card>
<card [idx]="1" [held]="selections[1]" [value]="cards[1]"
      [highlight]="highlights[1]" (click)="toggleSelection(1)"></card>
<card [idx]="2" [held]="selections[2]" [value]="cards[2]"
      [highlight]="highlights[2]" (click)="toggleSelection(2)"></card>
<card [idx]="3" [held]="selections[3]" [value]="cards[3]"
      [highlight]="highlights[3]" (click)="toggleSelection(3)"></card>
<card [idx]="4" [held]="selections[4]" [value]="cards[4]"
      [highlight]="highlights[4]" (click)="toggleSelection(4)"></card>
<div *ngIf="gameOver" class="game-over-banner">
  Game Over
</div>
