<div class="col hand">
  <div class="row" [ngClass]="{'selected': isRoyalFlush()}">
    Royal Flush
  </div>
  <div class="row" [ngClass]="{'selected': isStraightFlush()}">
    Straight Flush
  </div>
  <div class="row" [ngClass]="{'selected': isFourOfAKind()}">
    4 Of A Kind
  </div>
  <div class="row" [ngClass]="{'selected': isFullHouse()}">
    Full House
  </div>
  <div class="row" [ngClass]="{'selected': isFlush()}">
    Flush
  </div>
  <div class="row" [ngClass]="{'selected': isStraight()}">
    Straight
  </div>
  <div class="row" [ngClass]="{'selected': isThreeOfAKind()}">
    3 Of A Kind
  </div>
  <div class="row" [ngClass]="{'selected': isTwoPair()}">
    Two Pair
  </div>
  <div class="row" [ngClass]="{'selected': isJacksOrBetter()}">
    Jacks Or Better
  </div>
</div>
<div *ngFor="let payout of payouts; index as i" class="col payout"
     [ngClass]="{'selected': i + 1 == betAmount }">
  <div class="row" [ngClass]="{'selected': isRoyalFlush()}">
    {{payout[Hand.ROYAL_FLUSH]}}
  </div>
  <div class="row" [ngClass]="{'selected': isStraightFlush()}">
    {{payout[Hand.STRAIGHT_FLUSH]}}
  </div>
  <div class="row" [ngClass]="{'selected': isFourOfAKind()}">
    {{payout[Hand.FOUR_OF_A_KIND]}}
  </div>
  <div class="row" [ngClass]="{'selected': isFullHouse()}">
    {{payout[Hand.FULL_HOUSE]}}
  </div>
  <div class="row" [ngClass]="{'selected': isFlush()}">
   {{payout[Hand.FLUSH]}}
  </div>
  <div class="row" [ngClass]="{'selected': isStraight()}">
   {{payout[Hand.STRAIGHT]}}
  </div>
  <div class="row" [ngClass]="{'selected': isThreeOfAKind()}">
    {{payout[Hand.THREE_OF_A_KIND]}}
  </div>
  <div class="row" [ngClass]="{'selected': isTwoPair()}">
    {{payout[Hand.TWO_PAIR]}}
  </div>
  <div class="row" [ngClass]="{'selected': isJacksOrBetter()}">
    {{payout[Hand.JACKS_OR_BETTER]}}
  </div>
</div>
