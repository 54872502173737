<div [ngClass]="{'button': true, 'held': selections[0]}" (click)="toggleSelection(0)">
  <span *ngIf="!selections[0]">Hold</span>
  <span *ngIf="selections[0]">Held</span>
</div>
<div [ngClass]="{'button': true, 'held': selections[1]}" (click)="toggleSelection(1)">
  <span *ngIf="!selections[1]">Hold</span>
  <span *ngIf="selections[1]">Held</span>
</div>
<div [ngClass]="{'button': true, 'held': selections[2]}" (click)="toggleSelection(2)">
  <span *ngIf="!selections[2]">Hold</span>
  <span *ngIf="selections[2]">Held</span>
</div>
<div [ngClass]="{'button': true, 'held': selections[3]}" (click)="toggleSelection(3)">
  <span *ngIf="!selections[3]">Hold</span>
  <span *ngIf="selections[3]">Held</span>
</div>
<div [ngClass]="{'button': true, 'held': selections[4]}" (click)="toggleSelection(4)">
  <span *ngIf="!selections[4]">Hold</span>
  <span *ngIf="selections[4]">Held</span>
</div>
